<template>
  <div class="flex flex-col">
    <h1 class="text-theme-sidebr text-2xl font-poppins mb-1">{{ $t(locale.title) }}</h1>
    <b-card>
      <b-card-text>
        <b-container>
          <ValidationObserver ref="form">
            <b-row>
              <b-col cols="12" lg="4">
                <div class="flex flex-col">
                  <div class="mb-1">
                    <b-form-group
                        id="input-group-1"
                        :label="
                                                        $t(locale.sender)
                                                    "
                        label-for="input-1"
                    >
                      <!-- <label for="" class="text-base">{{ $t(locale.eventTitle) }}</label> -->
                      <div class="w-4/5">
                        <b-form-input
                            :name="smsForm.sender"
                            v-model="smsForm.sender"
                            max="11"
                            min="1"
                            :placeholder="
                                                            $t(locale.sender)
                                                        "
                        />
                      </div>

                      <!-- <small
                          class="text-danger"
                          v-text="errors[0]"
                      /> -->
                    </b-form-group>
                  </div>
                  <div class="mb-1">
                    <!-- <validation-provider
                        #default="{errors}"
                        :rules="'required'"
                        :name="$t(locale.eventTitle)"
                    > -->
                    <b-form-group
                        id="input-group-1"
                        :label="
                                                        $t(locale.eventTitle)
                                                    "
                        label-for="input-1"
                    >
                      <!-- <label for="" class="text-base">{{ $t(locale.eventTitle) }}</label> -->
                      <div class="w-4/5">
                        <b-form-input
                            :name="smsForm.event_title"
                            v-model="smsForm.event_title"
                            :placeholder="
                                                            $t(locale.eventTitle)
                                                        "
                        />
                      </div>

                      <!-- <small
                          class="text-danger"
                          v-text="errors[0]"
                      /> -->
                    </b-form-group>
                    <!-- </validation-provider> -->
                  </div>
                  <div class="mb-1">
                    <!-- <validation-provider
                        #default="{errors}"
                        :rules="'required'"
                        :name="$t(locale.recipient)"
                    > -->
                    <label for="" class="text-base">{{ $t(locale.recipient) }}</label>
                    <div class="grid grid-cols-3 gap-3 mb-1">
                                                <span v-for="(item, i) in selectedCategory" :key="item.uuid"
                                                      class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white">
                                                    <small>{{ item.name }}</small>
                                                    <span class="cursor-pointer" @click="removeCategory(i)">
                                                        <feather-icon icon="Trash2Icon"/>
                                                    </span>
                                                </span>
                    </div>
                    <div class="w-4/5">
                      <!-- <v-select
                          v-model="smsForm.members"
                          :options="memberCategory"
                          :clearable="false"
                          :placeholder="$t(locale.recipient)"
                          class="w-full per-page-selector d-inline-block"
                      >
                      </v-select> -->
                      <v-select class="w-full" v-model="emptyCate" label="name" @option:selected="setCategory($event)"
                                :filterable="false" :options="memberCategory" @search="searchCategories">
                        <template slot="no-options">
                          {{ $t('smsSectionCard.searchCategories') }}
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                      <!-- <small
                          class="text-danger"
                          v-text="errors[0]"
                      /> -->
                    </div>
                    <!-- </validation-provider> -->
                  </div>
                  <div class="mb-1">
                    <label for="" class="text-base">{{ $t(locale.members) }}</label>
                    <div class="grid grid-cols-3 gap-3 mb-1">
                                                <span v-for="(item, i) in selectedMembers" :key="item.uuid"
                                                      class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white">
                                                    <small>{{ item.first_name + ' ' + item.last_name }}</small>
                                                    <span class="cursor-pointer" @click="removeMember(i)">
                                                        <feather-icon icon="Trash2Icon"/>
                                                    </span>
                                                </span>
                    </div>
                    <div class="w-4/5">
                      <!-- <v-select
                          v-model="smsForm.member_categories"
                          :options="memberCategory"
                          :clearable="false"
                          :placeholder="$t(locale.members)"
                          class="w-full per-page-selector d-inline-block"
                      >
                      </v-select> -->
                      <v-select class="w-full" label="first_name" v-model="emptyCate"
                                @option:selected="setMembers($event)" :filterable="false" :options="members"
                                @search="searchMembers">
                        <template slot="no-options">
                          Sök...
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.first_name + ' ' + option.last_name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.first_name + ' ' + option.last_name }}
                          </div>
                        </template>
                      </v-select>
                      <!-- <small
                          class="text-danger"
                          v-text="errors[0]"
                      /> -->
                    </div>
                  </div>
                  <div class="mb-1">
                    <!-- <validation-provider
                        #default="{errors}"
                        :rules="'required'"
                        :name="$t(locale.formDate)"
                    > -->
                    <label for="" class="text-base">{{ $t(locale.formDate) }}</label>
                    <div class="w-1/2">
                      <date-picker
                          v-model="smsForm.send_date"
                          valueType="format"
                          class="w-100"
                          prefix-class="xmx"
                          :placeholder="$t(locale.formDate)"
                      />
                    </div>
                    <!-- <small
                        class="text-danger"
                        v-text="errors[0]"
                    />
                </validation-provider> -->
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </b-col>

              <b-col cols="12" lg="4">
                <div class="flex flex-col">
                  <div class="mb-1">
                    <validation-provider
                        #default="{errors}"
                        :rules="'required'"
                        :name="$t(locale.formMsg)"
                    >
                      <label for="" class="text-base">{{ $t(locale.formMsg) }}</label>
                      <div class="w-4/5">
                        <b-form-textarea
                            id="textarea"
                            v-model="smsForm.message"
                            :placeholder="$t(locale.formMsg)"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                      </div>
                      <small
                          class="text-danger"
                          v-text="errors[0]"
                      />
                    </validation-provider>
                  </div>
                  <div class="mb-1">
                    <label for="" class="text-base">{{ $t(locale.testPhone) }}
                      <small>({{ $t(locale.testingOnly) }})</small></label>
                    <div class="w-4/5">
                      <b-form-input
                          name="login-email"
                          v-model="smsForm.phone_number"
                          :placeholder="
                                                    $t(locale.testPhone)
                                                "
                      />
                    </div>
                  </div>
                  <div class="mb-1 w-4/5">
                    <button :disabled="sendingSms" @click="sendMessage()" type="submit"
                            class="flex p-1 w-full text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md">
                                            <span class="flex justify-center w-full" v-if="sendingSms">
                                                <b-spinner
                                                    small
                                                    type="grow"
                                                />
                                                {{ $t('Message.loading') }}...
                                            </span>

                      <span v-else
                            class="whitespace-nowrap text-center w-full text-base"
                            v-text="$t('smsSectionCard.form.sendSMS')"
                      />
                    </button>
                  </div>

                  <div class="w-4/5">
                    <button :disabled="sendingTest" @click="sendMessage('test')" type="submit"
                            class="flex p-1 w-full text-center focus:outline-none bg-theme-brown text-white whitespace-nowrap rounded-md">
                                            <span class="flex justify-center w-full" v-if="sendingTest">
                                                <b-spinner
                                                    small
                                                    type="grow"
                                                />
                                                {{ $t('Message.loading') }}...
                                            </span>

                      <span v-else
                            class="whitespace-nowrap text-center w-full text-base"
                            v-text="$t('smsSectionCard.form.sendTestSMS')"
                      />
                    </button>
                  </div>

                </div>
              </b-col>
              <b-col cols="12" lg="4">
                <div class="w-4/5">
                  <div class="flex flex-col custom-border border-blue-800 rounded-xl p-1">
                    <div><h2 class="text-gray-900 text-xl">{{ $t('customerCareCard.currentSMSPlan') }}</h2></div>
                    <div class="p-1">
                      <span v-if="subscriptions.length > 0 && subscriptions[0].sms_plan" class="flex flex-col"><span
                          class="text-5xl font-semibold text-gray-900 mr-1">{{
                          subscriptions[0].current_sms_plan.monthly_price
                        }}</span><span
                          class="flex justify-end w-full text-xl whitespace-nowrap">{{
                          $t('customerCareCard.perMonth')
                        }}</span></span>
                      <span v-else class="gap-5"><span class="text-5xl font-semibold text-gray-900 mr-1">0</span><span
                          class="text-xl">{{ $t('customerCareCard.perMonth') }}</span></span>
                      <hr>
                    </div>
                    <div class="flex items-center justify-between px-2">
                      <span class="text-gray-900">{{ $t('customerCareCard.used') }}</span>
                      <span v-if="subscriptions.length > 0 && subscriptions[0].sms_plan"
                            class="text-2xl text-green-500 font-semibold">{{
                          subscriptions[0].num_sms_sent
                        }}/{{ subscriptions[0].current_sms_plan.number_of_sms_per_month }}</span>
                      <span v-else class="text-2xl text-green-500 font-semibold">{{ 0 }}</span>
                    </div>
                    <div class="mt-2 p-1">
                      <router-link :to="{ name : 'smsPlanSubscription'}" type="submit"
                                   class="flex p-1 w-full text-center focus:outline-none bg-theme-sidebar text-white whitespace-nowrap rounded-md">
                        <!-- <span v-if="saving">
                            <b-spinner
                                small
                                type="grow"
                            />
                            {{ $t('Message.loading') }}...
                        </span> -->

                        <span
                            class="whitespace-nowrap uppercase text-center w-full text-2xl"
                            v-text="
                                                        $t('customerCareCard.upgrade') 
                                                    "
                        />
                      </router-link>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-container>
      </b-card-text>

      <!-- <b-card-title v-text="$t(locale.exportLogDetail)" /> -->

      <b-row class="mt-3">
        <b-col cols="12" lg="4">
          <h1 @click="filterForPrevious" class="text-theme-sidebar font-semibold text-xl cursor-pointer font-rubik">
            {{ $t('smsSectionCard.previousSendout') }}</h1>
        </b-col>
        <b-col cols="12" lg="4">
          <h1 @click="filterForSchedule" class="text-purple-900 font-semibold text-xl cursor-pointer font-rubik">
            {{ $t('smsSectionCard.scheduleSendout') }}</h1>
        </b-col>
      </b-row>

      <b-card-text>
        <b-table
            responsive
            sticky-header
            show-empty
            class="scrol-area"
            :items="smsResults"
            :fields="fields"
        >
          <template #head(eventId)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="$t(data.field.label)"
                            />
            </div>
          </template>
          <template #head(title)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="$t(locale.eventTitle)"
                            />
            </div>
          </template>

          <template #head(sendTime)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="$t(data.field.label)"
                            />
            </div>
          </template>

          <template #head(status)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="$t(data.field.label)"
                            />
            </div>
          </template>

          <template #head(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="$t(data.field.label)"
                            />
            </div>
          </template>
          <template #head(smsSent)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="$t(data.field.label)"
                            />
            </div>
          </template>
          <template #head(action)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="$t(data.field.label)"
                            />
            </div>
          </template>

          <template #cell(eventId)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="data.item.event_id"
                            />
            </div>
          </template>

          <template #cell(title)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="data.item.event_title"
                            />
            </div>
          </template>

          <template #cell(sendTime)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="data.item.send_date"
                            />
            </div>
          </template>

          <template #cell(status)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="data.item.errors && data.item.errors.length > 0 ? 'Error' : 'OK'"
                            />
            </div>
          </template>

          <template #cell(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="data.item.message"
                            />
            </div>
          </template>

          <template #cell(smsSent)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="data.item.amount_sent"
                            />
            </div>
          </template>

          <template #cell(action)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span></span>
              <span></span>
              <span class="cursor-pointer mr-0" @click="openSms(data.item)">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5 text-blue-800"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
                            </span>
              <span class="cursor-pointer" @click="removeSms(data.item)">
                                <feather-icon icon="Trash2Icon"/>
                            </span>
            </div>
          </template>
        </b-table>

        <div
            class="d-flex align-items-end flex-sm-column justify-content-between justify-content-sm-end"
        >
          <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalProducts"
              :per-page="queryParams.limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </div>
      </b-card-text>
    </b-card>

    <Modal
        id="modal-remove-sms"
        :hideFooter="true"
        :title="'Remove SMS'"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          Är du säker på att du vill radera SMS-utskicket?
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-remove-sms')"
        >
                <span
                    v-text="$t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="removeSmsInfo"
        >
                <span
                    v-text="sendingModalAction ? $t('Message.loading') :  $t('Message.proceed')"
                />
        </b-button>
      </div>
    </Modal>
    <Modal
        id="modal-open-sms"
        :hideFooter="true"
        :hideHeader="true"
        :title="``"
        :size="'md'"
        v-if="showDetail"
    >
      <span class="absolute right-3 cursor-pointer" @click="closeDataDetail"><i class="fa fa-times"></i></span>
      <div class="mt-3">
        <h3>SMS UTSKICK - {{ selectedData.send_date }}</h3>
        <hr>
      </div>
      <div v-if="selectedData" class="mb-2">
        <p class="mb-0 pb-0 text-lg font-semibold text-sky-700 text-theme-sidebar">Sammanfattning</p>
        <p class="mb-0 pb-0 text-base">Meddelandet skickades till {{ parseArrayMessage(selectedData.receiving_members) }}
          {{ selectedData.send_date }}</p>
      </div>
      <div v-if="selectedData" class="mb-2">
        <p class="mb-0 pb-0 text-lg font-semibold text-sky-700 text-theme-sidebar">Felmeddelanden</p>
        <div class="flex">
          <table class="table">
            <tbody>
            <tr v-for="(item, i) in selectedData.errors" :key="i">
              <td class="custom-tbl-td">
                <span>{{ item }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="w-full mb-2">
        <p class="mb-0 pb-0 text-lg font-semibold text-sky-700 text-theme-sidebar">Meddelande</p>
        <textarea
            id="textarea"
            v-model="selectedData.message"
            placeholder="Enter something..."
            class="w-full message-text-box"
            cols="5"
            rows="6"
        ></textarea>
      </div>
      <div class="w-full">
        <p class="mb-0 pb-0 text-lg font-semibold text-sky-700 text-theme-sidebar">Mottagare</p>
        <p class="mb-0 pb-0 text-base"> {{
            parseArrayMessage(selectedData.receiving_members)
          }}/{{ selectedData.number_intended_receivers }} medlemmar mottog SMS:et</p>
        <table class="table">
          <tbody>
          <tr>
            <td class="custom-tbl-td">
              <span class="font-semibold flex text-theme-sidebar pl-2.5">
                Medlemmar
              </span>
            </td>
          </tr>
          <tr v-for="(member ,i) in combineMessage(selectedData)" :key="i">
            <td class="custom-tbl-td">
              <span class="font-semibold flex">
                <span><svg v-if="member.type === 'success'"
                           xmlns="http://www.w3.org/2000/svg"
                           class="h-5 w-5 text-green-500"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                >
                      <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                      />
                    </svg>
                <svg v-else
                     xmlns="http://www.w3.org/2000/svg"
                     class="h-5 w-5 text-red-500"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                     stroke-width="2"
                >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg></span>
                <span class="relative top-0.5 ml-1">{{ member.golf_id }} {{ member.name }}</span>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </Modal>
    <Modal
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied :message="accessDeniedMessage"/>
    </Modal>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import vSelect from 'vue-select';
import DatePicker from 'vue2-datepicker';
import debounce from 'lodash.debounce'
import {dateMixins} from '@/mixins/dateMixins';
import useAppConfig from '@core/app-config/useAppConfig';
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import {mapGetters} from "vuex"
import moment from "moment";

import accessDenied from "@core/components/permission/accessDenied";

export default {
  name: 'SMSSendout',
  mixins: [dateMixins, getSubscribersMixins],
  components: {
    DatePicker,
    vSelect,
    ValidationProvider,
    ValidationObserver, accessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      accessDeniedMessage: null,
      sendingSms: false,
      sendingTest: false,
      showDetail: false,
      sendingModalAction: false,
      emptyCate: '',
      smsResults: [],
      copySmsResults: [],
      subscriptions: [],
      smsForm: {
        member_categories: [],
        members: [],
        message: '',
        send_date: '',
        event_title: '',
        sender: '',
        phone_number: ''
      },
      openModal: false,
      selectedData: {},
      memberCategory: [],
      members: [],
      selectedCategory: [],
      selectedMembers: [],
      items: [],
      queryParams: {
        limit: 30,
      },
      pagination: {
        currentPage: 1,
        totalProducts: 0,
      },
      fields: [
        {
          key: 'eventId',
          label: 'smsSectionCard.table.eventID',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 10px',
            width: '',
          },
        },
        {
          key: 'title',
          label: 'smsSectionCard.table.eventTitle',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '150px',
          },
        },
        {
          key: 'sendTime',
          label: 'smsSectionCard.table.sendTime',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '90px',
          },
        },
        {
          key: 'status',
          label: 'smsSectionCard.table.status',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '100px',
          },
        },
        {
          key: 'message',
          label: 'smsSectionCard.table.message',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '200px',
          },
        },
        {
          key: 'smsSent',
          label: 'smsSectionCard.table.smsSent',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '50',
          },
        },
        {
          key: 'action',
          label: 'smsSectionCard.table.action',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '80px',
          },
        },
      ],
      locale: {
        sender: "smsSectionCard.form.sender",
        title: "smsSectionCard.title",
        eventTitle: "smsSectionCard.form.eventTitle",
        recipient: "smsSectionCard.form.memberCategories",
        members: "smsSectionCard.form.members",
        formDate: "smsSectionCard.form.sendDate",
        formMsg: "smsSectionCard.form.message",
        formSend: "smsSectionCard.form.sendSMS",
        formTest: "smsSectionCard.form.sendTestSMS",
        previous: "smsSectionCard.previousSendout",
        schdule: "smsSectionCard.scheduleSendout",
        tableId: "smsSectionCard.table.eventID",
        tableTitle: "smsSectionCard.table.eventTitle",
        tableSendTime: "smsSectionCard.table.sendTime",
        tableStatus: "smsSectionCard.table.status",
        tableMsg: "smsSectionCard.table.message",
        tableSmsSent: "smsSectionCard.table.smsSent",
        tableAction: "smsSectionCard.table.action",
        testPhone: 'smsSectionCard.form.testPhone',
        testingOnly: 'emailSectionCard.form.testingOnly',

        // upload: 'upload',
        // uploadInvoiceTitle: 'exportCard.uploadInvoiceTitle',
        // alertPermited: 'exportCard.alertPermited',
        // downloadExcel: 'exportCard.downloadExcel',
        // memberTitle: 'exportCard.memberTitle',
        // articleFooter: 'exportCard.articleFooter',
        // exportLogDetail: 'exportCard.exportLogDetails',
      },
    };
  },
  computed: {
    messageList() {
      return this.$t('exportCard.articleList');
    },
    customMsg() {
      if (this.getLang == '') {
        return this.swedishLang ? 'message' : 'eng_message';
      } else return this.getLang == 'sv' ? 'message' : 'eng_message';
    },
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    features() {
      if (this.getLang == '') {
        return this.swedishLang ? 'features' : 'features';
      } else return this.getLang == 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    closeDataDetail() {
      this.showDetail = false
      this.selectedData = {}
      this.$bvModal.hide('modal-open-sms')
    },
    parseArrayMessage(msg) {
      if (msg === null) {
        return 0
      }
      return msg.length
    },
    getAllMembersCategory() {
      this.$useJwt
          .getMembersCategory()
          .then(res => {
            if (res.data.data) {
              this.memberCategory = res.data.data.results
            }
          })

    },
    sendMessage(data = null) {
      this.$refs.form.validate().then(res => {
        if (res) {
          if (this.smsForm.sender === "") {
            this.popupMsg(
                this.$t('Message.Failed'),
                this.$t('smsSectionCard.senderNameRequired'),
                'AlertTriangleIcon',
                'danger'
            );
            return
          }
          if (this.smsForm.sender.length < 1 || this.smsForm.sender.length > 11) {
            this.popupMsg(
                this.$t('Message.Failed'),
                this.$t('smsSectionCard.sendNameLengthError'),
                'AlertTriangleIcon',
                'danger'
            );
            return
          }
          if (data === 'test' && this.smsForm.phone_number === '') {
            this.popupMsg(
                this.$t('Message.Failed'),
                'Telefonnummer är obligatoriskt',
                'AlertTriangleIcon',
                'danger'
            );
            return
          }
          if (data !== 'test' && this.validatePresentDate(this.smsForm.send_date) === false) {
            this.popupMsg(
                this.$t('Message.Failed'),
                'Date is in past',
                'AlertTriangleIcon',
                'danger'
            );
            return false
          }
          let url = ''
          const payload = {
            member_categories: [],
            members: [],
            message: this.smsForm.message,
            event_title: this.smsForm.event_title,
            sender: this.smsForm.sender,
          }
          if (this.smsForm.send_date) {
            payload['send_date'] = this.parseFormatDate(this.smsForm.send_date)
          }
          if (data === 'test') {
            this.sendingTest = true
            url = 'sms/test'
            payload.phone_number = this.smsForm.phone_number
            payload.sender = this.smsForm.sender
          } else {
            url = 'sms'
            this.sendingSms = true
          }
          if (this.selectedCategory.length > 0) {
            const array = []
            this.selectedCategory.map(el => {
              array.push(el.uuid)
            })
            payload.member_categories = array
          }

          if (this.selectedMembers.length > 0) {
            const array = []
            this.selectedMembers.map(el => {
              array.push(el.uuid)
            })
            payload.members = array
          }

          this.$useJwt.memberCare({URL: url, method: 'post'}, payload)
              .then(res => {
                this.$refs.form.reset()
                this.getSmsPlan()
                this.getMemberCareSMS()
                this.sendingTest = false
                this.sendingSms = false
                this.clearField()

                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.locale_message],
                    'CheckIcon',
                    'success'
                );
              })
              .catch(err => {
                this.sendingTest = false
                this.sendingSms = false
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data[this.locale_message],
                    'AlertTriangleIcon',
                    'danger'
                );
              })

        }
      })
    },
    parseFormatDate(date_string) {
      return moment(date_string).format('YYYY-MM-DD')

    },
    filterForPrevious() {
      const filter = this.copySmsResults.filter(el => this.validatePresentDate(el.send_date) === false)
      this.smsResults = filter
    },
    filterForSchedule() {
      const filter = this.copySmsResults.filter(el => this.validatePresentDate(el.send_date) === true)
      this.smsResults = filter
    },
    getMemberCareSMS() {
      this.$useJwt.memberCare({URL: 'sms', method: 'get'})
          .then(res => {
            this.smsResults = res.data.data
            this.copySmsResults = res.data.data
          }).catch(err => {
            if (err.response.status === 403) {
              this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
              this.accessDeniedMessage = err.response.data[this.locale_message]
              this.$bvModal.show("modal-access-denied");
            }
      })
    },
    removeCategory(index) {
      this.selectedCategory.splice(index, 1)
    },
    removeMember(index) {
      this.selectedMembers.splice(index, 1)
    },
    searchMembers(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteMembers(loading, search, this);
      }
    },
    searchRemoteMembers: debounce(function (loading, search, vm) {
      this.$useJwt.getMembers({URL: '?search=' + escape(search), method: 'get'})
          .then(res => {
            this.members = res.data.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    fetchMember: function () {
      this.$useJwt
          .getMembers({URL: "", method: "get"})
          .then((res) => {
            this.members = res.data.data.results;
          })
          .catch((err) => {
          });
    },
    setMembers(item) {
      const uuid = this.selectedMembers.filter(el => el.uuid === item.uuid)
      if (uuid.length < 1) {
        this.selectedMembers.push(item)
      }
      this.emptyCate = ''
    },
    searchCategories(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteMemberCate(loading, search, this);
      }
    },
    searchRemoteMemberCate: debounce(function (loading, search, vm) {
      this.$useJwt.getMembersCategory({URL: '?search=' + escape(search), method: 'get'})
          .then(res => {
            this.memberCategory = res.data.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    fetchCategory: function () {
      this.$useJwt
          .getMembersCategory({URL: "", method: "get"})
          .then((res) => {
            this.memberCategory = res.data.data.results;
          })
          .catch((err) => {
          });
    },
    setCategory(item) {
      const uuid = this.selectedCategory.filter(el => el.uuid === item.uuid)
      if (uuid.length < 1) {
        this.selectedCategory.push(item)
      }
      this.emptyCate = ''
    },
    async getSmsPlan() {
      const {data} = await this.getSubscriptions()
      this.subscriptions = data.data
    },
    combineMessage(selectData) {
      let data = []
      if (typeof selectData.receiving_members === 'object') {
        selectData.receiving_members.map(item => {
          item.type = 'success'
          data.push(item)
        })
      }
      if (typeof selectData.not_receiving_members === 'object') {
        selectData.not_receiving_members.map(item => {
          item.type = 'fail'
          data.push(item)
        })
      }
      return data
    },
    removeSmsInfo() {
      this.sendingModalAction = true
      this.$useJwt.memberCare({method: 'delete', URL: `sms/${this.selectedData.uuid}`})
          .then(res => {
            this.sendingModalAction = false
            this.getMemberCareSMS()
            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.locale_message],
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.sendingModalAction = false
            this.popupMsg(
                this.$t('Message.Failed'),
                err.response.data[this.locale_message],
                'AlertTriangleIcon',
                'danger'
            );
          })
    },
    removeSms(item) {
      this.openModal = true
      this.selectedData = item
      this.$bvModal.show('modal-remove-sms')
    },
    openSms(item) {
      this.showDetail = true
      this.selectedData = item
      this.$bvModal.show('modal-open-sms')
    },
    clearField() {
      this.selectedMembers = []
      this.selectedCategory = []
      this.smsForm.member_categories = []
      this.smsForm.members = []
      this.smsForm.message = ''
      this.smsForm.send_date = ''
      this.smsForm.event_title = ''
      this.smsForm.phone_number = ''
    }
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getSmsPlan()
    this.getMemberCareSMS()
    this.fetchMember()
    this.fetchCategory()
  },
};
</script>

<style scoped lang="scss">
.resource-selector {
  width: 100%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/datepicker.scss';

.message-text-box {
  border: 2px solid rgba(22, 20, 159);
  border-radius: 8px;
  padding: 7px;
}

.custom-tbl-td {
  padding-left: 0px !important;
  border-top: 1px solid #fff !important;
  border-bottom: 1px solid #ebe9f1;
}
</style>
